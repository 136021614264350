<template>
  <div>
    <h2 class="mb-2">Settings</h2>
    <template v-if="defaultAlarms.length">
      <!-- <h4>Default</h4> -->
      <div class="switch-row my-2">
        <v-icon class="mr-1" color="#009006">mdi-map-clock</v-icon>
        <span class="mr-2">Timezone</span>
        <div class="d-flex custom-select">
          <v-select
            class="mr-2"
            dense
            hide-details
            solo
            :items="timezones"
            v-model="sensorDetails.timezone"
            @change="updateTimezone($event)"
            color="#009006"
          ></v-select>
        </div>
      </div>
      <div class="switch-row my-2" v-for="(item, ind) in defaultAlarms" :key="item.id">
        <template v-if="item.type === 'delay'">
          <v-icon class="mr-1 off-hours" color="#009006">mdi-clock</v-icon>
          <span class="mr-2">Mute</span>
          <div class="d-flex custom-select">
              <v-select
              class="mr-2"
              dense
              hide-details
              solo
              :items="hours"
              v-model="item.start"
              @change="updateHours(item)"
              ></v-select>
              <v-select
              class="mr-3"
              dense
              hide-details
              solo
              :items="hours"
              v-model="item.stop"
              @change="updateHours(item)"
              :readonly="isLocation"
              ></v-select>
          </div>
        </template>
        <template v-else-if="item.type === 'connectivity'">
          <v-icon v-if="isSuper" @click="acknowledge(item)" class="mr-1" :color="alarmColorCheck(item)">mdi-battery-charging-wireless-alert</v-icon>
          <v-icon v-else class="mr-1" @click="acknowledge(item)" :color="alarmColorCheck(item)">mdi-battery-charging-wireless-alert</v-icon>
          <span class="mr-3">Connectivity Check</span>
        </template>
        <template v-else-if="item.type === 'leak'">
          <v-icon class="mr-1" :color="alarmColorCheck(item)">mdi-water-alert</v-icon>
          <span class="mr-3">Leak</span>
        </template>
        <template v-else-if="item.type === 'high'">
          <v-icon @click="acknowledge(item)" class="mr-1" :color="alarmColorCheck(item)">mdi-fire</v-icon>
          <span class="mr-2">High</span>
          <!-- <span class="mr-3 hidden-xs-only">{{ item.input }}</span> -->
          <div class="d-flex custom-text" style="min-width: 85px;">
            <v-text-field v-if="userDetails.degreePref === 'Celsius'" :readonly="isLocation" @change="updateTemp($event, item.id, 'highC')" type="number" suffix="°C" dense hide-details v-model="item.valueC" solo></v-text-field>
            <v-text-field v-else :readonly="isLocation" @change="updateTemp($event, item.id, 'high')" type="number" suffix="°F" dense hide-details v-model="item.value" solo></v-text-field>
          </div>
          <span class="mx-2">for</span>
          <div class="d-flex custom-select">
            <v-select
            class="mr-3"
            dense
            hide-details
            solo
            :items="durations"
            v-model="item.duration"
            @change="updateDuration(item)"
            :readonly="isLocation"
            >
            <template v-slot:append>
              <div class="v-input__append-inner">mins<div class="v-input__icon v-input__icon--append"><i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light"></i></div></div>
            </template>
            </v-select>
          </div>
        </template>
        <template v-else-if="item.type === 'low'">
          <v-icon @click="acknowledge(item)" class="mr-1" :color="alarmColorCheck(item)">mdi-snowflake</v-icon>
          <span class="mr-2">Low</span>
          <!-- <span class="mr-3 hidden-xs-only">{{ item.input }}</span> -->
          <div class="d-flex custom-text" style="min-width: 85px;">
            <v-text-field v-if="userDetails.degreePref === 'Celsius'" :readonly="isLocation" @change="updateTemp($event, item.id, 'lowC')" type="number" suffix="°C" dense hide-details v-model="item.valueC" solo></v-text-field>
            <v-text-field v-else :readonly="isLocation" @change="updateTemp($event, item.id, 'low')" type="number" suffix="°F" dense hide-details v-model="item.value" solo></v-text-field>
          </div>
          <span class="mx-2">for</span>
          <div class="d-flex custom-select">
              <v-select
              class="mr-3"
              dense
              hide-details
              solo
              :items="durations"
              v-model="item.duration"
              @change="updateDuration(item)"
              :readonly="isLocation"
              >
              <template v-slot:append>
                <div class="v-input__append-inner">mins<div class="v-input__icon v-input__icon--append"><i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light"></i></div></div>
              </template>
              </v-select>
          </div>
        </template>
        <v-switch
          :false-value="true"
          :true-value="false"
          :value="item.pause ? true : false"
          :input-value="item.pause ? true : false"
          inset
          dense
          color="green"
          hide-details
          @change="toggleAlarm(item.id, $event)"
          :readonly="isLocation"
          class="ma-0 pa-0 paused"
          :label="`${item.pause ? 'OFF' : 'ON'}`"
        ></v-switch>
      </div>
    </template>
    <template v-if="customAlarms.length">
      <h4 class="mt-2">Custom</h4>
      <ol>
        <li class="my-2" v-for="(item, ind) in customAlarms" :key="item.id">
          <div class="switch-row">
            <span
              class="mr-3"
            >{{ item.input }} {{ checkPredicate(item.predicate) }} {{ item.value }}&#8457; for {{ item.duration }} mins</span>
            <v-switch
              :false-value="true"
              :true-value="false"
              :value="item.pause ? true : false"
              :input-value="item.pause ? true : false"
              inset
              dense
              color="green"
              hide-details
              @change="toggleAlarm(item.id, $event)"
              :readonly="isLocation"
              class="ma-0 pa-0 paused"
              :label="`${item.pause ? 'Paused' : 'Resume'}`"
            ></v-switch>
            <v-icon color="red" @click="confirmDelete(item.id, ind)">mdi-trash-can-outline</v-icon>
          </div>
        </li>
      </ol>
    </template>
    <!-- <v-row align="center">
      <v-col cols="4" sm="3">
        <v-select :readonly="deviceInputs.length === 1" dense hide-details v-model="input" :items="deviceInputs" solo></v-select>
      </v-col>
      <v-col cols="2" md="2" class="font-weight-medium">
        <v-select :readonly="isLocation" dense hide-details v-model="predicate" :items="predicates" solo></v-select>
      </v-col>
      <v-col cols="3" sm="2">
        <v-text-field :readonly="isLocation" dense hide-details v-model="value" solo></v-text-field>
      </v-col>
      <v-col cols="2" md="2">
        <v-select :readonly="isLocation" dense hide-details v-model="duration" solo :items="durations"></v-select>
      </v-col>
      <v-col cols="1">
        <v-icon medium :color="siteColor" @click="addAlarm">mdi-check-circle-outline</v-icon>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "alarms",
  data() {
    return {
      test: false,
      hours: [
        {
          text: "12am",
          value: "0"
        },
        {
          text: "1am",
          value: "1"
        },
        {
          text: "2am",
          value: "2"
        },
        {
          text: "3am",
          value: "3"
        },
        {
          text: "4am",
          value: "4"
        },
        {
          text: "5am",
          value: "5"
        },
        {
          text: "6am",
          value: "6"
        },
        {
          text: "7am",
          value: "7"
        },
        {
          text: "8am",
          value: "8"
        },
        {
          text: "9am",
          value: "9"
        },
        {
          text: "10am",
          value: "10"
        },
        {
          text: "11am",
          value: "11"
        },
        {
          text: "12pm",
          value: "12"
        },
        {
          text: "1pm",
          value: "13"
        },
        {
          text: "2pm",
          value: "14"
        },
        {
          text: "3pm",
          value: "15"
        },
        {
          text: "4pm",
          value: "16"
        },
        {
          text: "5pm",
          value: "17"
        },
        {
          text: "6pm",
          value: "18"
        },
        {
          text: "7pm",
          value: "19"
        },
        {
          text: "8pm",
          value: "20"
        },
        {
          text: "9pm",
          value: "21"
        },
        {
          text: "10pm",
          value: "22"
        },
        {
          text: "11pm",
          value: "23"
        }
      ],
      timezones: [
        {
          text: "New York",
          value: "America/New_York"
        },
        {
          text: "Chicago",
          value: "America/Chicago"
        },
        {
          text: "Denver",
          value: "America/Denver"
        },
        {
          text: "Phoenix",
          value: "America/Phoenix"
        },
        {
          text: "Los Angeles",
          value: "America/Los_Angeles"
        },
        {
          text: "Anchorage",
          value: "America/Anchorage"
        },
        {
          text: "Honolulu",
          value: "Pacific/Honolulu"
        }
      ],
      sensorId: "",
      // alarms: "",
      value: "45",
      predicate: "gt",
      // predicates: ["=", "!=", ">", "<", ">=", "<="],
      predicates: ["gt","lt"],
      duration: "30",
      durations: ["30", "40", "50", "60"],
      input: "",
    };
  },
  props: ["sensorAlarm"],
  components: {},
  async created() {
    this.$store.dispatch("sensor/sortAndSetAlarms", this.sensorAlarm.alarms.items);
    // this.sensorId = await this.$store.dispatch(
    //   "sensor/getSensorAlarms",
    //   this.$route.params.id
    // )
    this.sensorId = this.sensorAlarm.id;
  },
  computed: {
    ...mapState("sensor", ["sensor", "deviceType", "sensorDetails", "alarms"]),
    ...mapState("user", ["userDetails"]),
    ...mapGetters("user", ["isMDX", "isKDX", "isTDX", "isLocation", "isSuper"]),
    deviceInputs() {
      let vals;
      if (this.deviceType === "MDX") {
        vals = [
          "temp1",
          "temp2",
          "temp3",
          "temp4",
          "pressure1",
          "pressure2",
          "pressure3",
          "pressure4",
          "pressure5",
          "pressure6",
          "voltage1",
          "voltage2",
          "voltage3",
        ];
      } else {
        vals = ["temp"];
      }
      this.input = vals[0];
      return vals;
    },
    defaultAlarms() {
      if (this.alarms.length) {
        return this.alarms
          .filter((item) => item.default)
          // .sort((a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0))
          .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
      } else return [];
    },
    customAlarms() {
      if (this.alarms.length) {
        return this.alarms.filter((item) => !item.default);
      } else return [];
    },
  },
  methods: {
    cToF(temp) {
      return ((temp * 9/5) + 32).toFixed(0)
    },
    fToC(temp) {
      return ((temp - 32) * 5/9).toFixed(0)
    },
    async acknowledge(alarm) {
      if (this.isSuper && !alarm.active && !alarm.ack && alarm.type === "connectivity") {
        await this.adminConnectivityTest()
      }
      else if (!alarm.active || alarm.ack) {
        return
      }
      else {
        await this.$store.dispatch("sensor/acknowledge", {
          alarmId: alarm.id,
          eventId: alarm.events.items[0].id,
          responseBy: `${this.userDetails.first} ${this.userDetails.last}`,
          sensorId: this.sensorId
        })
      }      
    },
    async adminConnectivityTest() {
      this.test = true
      let checkin = new Date(this.sensorDetails.lastCheckIn)
      let value = new Date(checkin.setHours(checkin.getHours() - 1)).toISOString()
      let res = await this.$store.dispatch("sensor/updateSensorPropertySimple", {
        id: this.sensorDetails.id,
        prop: "lastCheckIn",
        value
      })
      await this.$store.dispatch("sensor/testConnectivity")
      this.test = false
    },
    async updateTemp(temp, id, prop) {
      if (prop === "low" || prop === "lowC") {
        await this.$store.dispatch("sensor/updateAlarmPropertyTemps", {
          id,
          value: prop === "low" ? temp : this.cToF(temp),
          valueC: prop === "lowC" ? temp : this.fToC(temp),
          sensorId: this.sensorDetails.id,
          type: "low",
          update: true
        })
      }
      else if (prop === "high" || prop === "highC") {
        await this.$store.dispatch("sensor/updateAlarmPropertyTemps", {
          id,
          value: prop === "high" ? temp : this.cToF(temp),
          valueC: prop === "highC" ? temp : this.fToC(temp),
          sensorId: this.sensorDetails.id,
          type: "high",
          update: true
        })
      }
    },
    async updateDuration(alarm) {
      await this.$store.dispatch("sensor/updateAlarmDuration", {
        id: alarm.id,
        duration: alarm.duration
      })
    },
    async updateHours(alarm) {
      await this.$store.dispatch("sensor/updateAlarmHours", {
        id: alarm.id,
        start: alarm.start,
        stop: alarm.stop,
        timezone: alarm.timezone ? alarm.timezone : this.$moment.tz.guess()
      })
    },
    async updateTimezone(timezone) {
      await this.$store.dispatch("sensor/adminUpdateSensor", {
        id: this.sensorDetails.id,
        // alarms: this.sensorDetails.alarms.items.map(item => item.id),
        timezone
      })
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    },
    alarmColorCheck(item) {
      if (this.test && item.type === "connectivity") return 'black'
      else if (item.active && !item.ack) return 'red'
      else if (item.active) {
        if (item.type === "connectivity") return 'blue'
        else return 'orange'
      }
      else return '#009006'
    },
    checkPredicate(predicate) {
      if (predicate === "eq") {
        return "=";
      } else if (predicate === "ne") {
        return "!=";
      } else if (predicate === "gt") {
        return "greater than";
      } else if (predicate === "lt") {
        return "less than";
      } else if (predicate === "ge") {
        return ">=";
      } else if (predicate === "le") {
        return "<=";
      }
    },
    convertPredicate(predicate) {
      if (predicate === "=") {
        return "eq";
      } else if (predicate === "!=") {
        return "ne";
      } else if (predicate === ">") {
        return "gt";
      } else if (predicate === "<") {
        return "lt";
      } else if (predicate === ">=") {
        return "ge";
      } else if (predicate === "<=") {
        return "le";
      }
    },
    confirmDelete(id, ind) {
      if (this.isLocation) return
      this.$swal({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      'cancelButtonText': 'No',
      'iconColor': '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteAlarm(id, ind)
        }
      });
    },
    async deleteAlarm(id, ind) {
      if (this.isKDX || this.isTDX || this.isMDX) return;
      let alarm = await this.$store.dispatch("sensor/removeAlarm", id);
      if (alarm) {
        let index = this.alarms.findIndex((item) => item.id === id);
        this.alarms.splice(index, 1);
      }
    },
    async toggleAlarm(id, pause) {
      if (this.isKDX || this.isTDX || this.isMDX) return;
      let alarm = {
        id,
        pause,
      };
      let result = await this.$store.dispatch("sensor/pauseAlarm", alarm);
      if (result) {
        let index = this.alarms.findIndex((item) => item.id === result.id);
        this.alarms[index].pause = result.pause;
      }
    },
    async addAlarm() {
      if (this.isKDX || this.isTDX || this.isMDX || this.isLocation) return;
      let alarm = await this.$store.dispatch("sensor/addAlarm", {
        input: this.input,
        value: this.value,
        // predicate: this.convertPredicate(this.predicate),
        predicate: this.predicate,
        duration: this.duration,
        sensor: this.sensorId,
      });
      if (alarm) {
        this.alarms.push(alarm);
      } else {
        console.log("error adding alarm");
      }
    },
  },
};
</script>

<style lang="scss">
.ack {
  color: white !important
}
i {
  outline: none;
}
.switch-row {
  display: flex;
  align-items: center;
  span {
    width: max-content;
  }
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
  .paused.theme--light.v-input--switch {
    .v-input--switch__track {
      color: red;
    }
    .v-input--switch__thumb {
      color: red;
    }
  }
  .d-flex {
    flex-basis: 0;
    .v-select__selection--comma{
      overflow: inherit;
    }
  }
}
.off-hours {
  font-size: 22px !important;
}
.custom-select {
  .v-input__control {
    // .v-input__slot{
    //   padding: 0 10px !important;
    // }
    .v-select__selection--comma {
      margin: 0;
    }
  }
}
.custom-text {
  .v-text-field__slot{
    input {
      text-align: center;
    }
  }
  .v-text-field__suffix{
    padding-left: 0;
  }
}
.v-input__append-inner {
  align-items: center;
  padding-left: 2px !important;
}

</style>